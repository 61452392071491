<template>
  <div>
    <v-data-table :headers="headers" :items="payouts">
      <template #[`item.createdAt`]="{ item }">
        {{ item.createdAt | date_format }}
      </template>
      <template #[`item.amountTransferred`]="{ item }">
        {{ item.amountTransferred | dollar_format }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import GetPayouts from "@/graphql/queries/GetPayouts.gql";
import moment from "moment";
export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: function() {
    return {
      headers: [
        { text: "Created at", value: "createdAt" },
        { text: "Amount", value: "amountTransferred" },
      ],
    };
  },
  apollo: {
    payouts: {
      query: GetPayouts,
    },
  },
};
</script>

<style></style>
